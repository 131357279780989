export default {
    name: 'Виктория',
    position: 'Менеджер отдела продаж',
    personImg: './img/content/person_chery.png',
    dealer: 'Форвард-Авто Chery Уфа',
    brand: 'CHERY',
    address: 'г. Уфа, пр. Салавата Юлаева, 60',
    ogranization: 'ООО Автосеть Уфа<br>ИНН: 0276945049<br>ОГРН: 1190280056064<br>450071, Республика Башкортостан, г. Уфа<br>пр. Салавата Юлаева, 60',
    phone: '+7 (347) 200-92-03',
    banner: './img/content/banners/intro',
    title: 'Онлайн-подбор автомобилей CHERY с максимальной выгодой',
    subtitle: 'Узнайте реальную стоимость CHERY от официального дилера',
    mainSite: 'https://ufa.forward-chery.ru/',
    policyTextLink: 'https://forward-autogroup.ru/sites/all/themes/mirsaitov/files/Polit.pdf',
}