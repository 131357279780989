import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: "/",
        name: "Chat",
        component: () => import("../views/ChatView.vue")
    },
    {
        path: "/success",
        name: "Success",
        component: () => import("../views/SuccessView.vue")
    },
    {
        path: "/policy",
        name: "Policy",
        component: () => import("../views/PolicyView.vue")
    }
];

const router = createRouter({
    history: createWebHistory("/"),
    routes
});

export default router;
