<template>
  <header class="header header_forward">
    <div class="header__container container-xsm">
      <a href="/" :class="`header__logo`">
        <picture>
          <img :src="`img/content/logo_${dealer.brand.toLowerCase()}.svg`" width="113" height="44">
        </picture>
        <span class="header__dealer">
          Официальный дилерский центр Chery<br>{{ dealer.dealer }}
        </span>
      </a>
      <div class="header__right-block">
        <a class="header__phone" :href="`tel:+${dealer.phone.replace(/[^0-9]/g, '')}`">{{ dealer.phone }}</a>
        <span class="header__address" v-html="`${dealer.address}`"></span>
      </div>
    </div>
  </header>
  <router-view></router-view>
</template>

<script>
// Данные ДЦ
import dealer from '@/data//dealer.js'

export default {
  name: 'App',

  data() {
    return {
      dealer: dealer,
    }
  },
}

</script>

<style>
.chat__warning {
  margin-top: 40px;
  font-size: 12px;
  color: #C0C0CC;
}
</style>
